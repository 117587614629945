<template>
  <span v-if="team">
    <template v-for="(player, i) in team.players">
      <player-link
        :player="player"
        :key="player.id"
      ></player-link>
      {{ getSlash(i) }}
    </template>
  </span>
</template>

<script>
import PlayerLink from '@/components/Player/PlayerLink.vue'

export default {
  props: ['team'],
  components: {
    'player-link': PlayerLink
  },
  methods: {
    getSlash (i) {
      return i + 1 < this.team.players.length ? '/' : ''
    }
  }
}
</script>

<style>

</style>
